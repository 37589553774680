@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: "Poppins";
  background-color: #ddffaa;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3E%3Cpolygon fill='%23AE9' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/%3E%3C/svg%3E");
  margin: 0;
  font-family: Poppins,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.button{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px;
  border: none;
  width: 180px;
  background: #384b3d;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #DFDEDF;

}


.element {
  height: 100vh;
  opacity: 0; /* Initial opacity set to 0 */
  animation: zoomInOpacity 0.7s ease-in-out forwards; /* Apply the animation */
}

@keyframes zoomInOpacity {
  0% {
    opacity: 0;
    transform: scale(0.5); /* Start from a smaller size */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at normal size */
  }
}
