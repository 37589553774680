.bordered-area {
  border: 2px solid red; /* Add border style for clicked areas */
}

.circle {
  width: 60px;
  height: 60px;
  border: 4px solid red;
  border-radius: 50%;
}

.object-1 {
  width: 80px;
  height: 80px;
  border: 4px solid red;
  border-radius: 50%;
  top: 26%;
  left: 4%;
}

.object-2 {
  width: 40px;
  height: 40px;
  border: 4px solid red;
  border-radius: 50%;
  top: 46%;
  left: 34%;
}
.object-3 {
  width: 65px;
  height: 65px;
  border: 4px solid red;
  border-radius: 50%;
  top: 86%;
  left: 0.5%;
}
.object-4 {
  width: 70px;
  height: 70px;
  border: 4px solid red;
  border-radius: 50%;
  top: 76%;
  left: 87%;
}

.object-5 {
  width: 60px;
  height: 60px;
  border: 4px solid red;
  border-radius: 50%;
  top: 20%;
  left: 66%;
}

@media (max-width: 1200px) {
  .image {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 980px) {
  .image {
    width: 300px;
    height: 300px;
  }

  .object-1 {
    width: 40px;
    height: 40px;
    border: 3px solid red;
    border-radius: 50%;
    top: 27%;
    left: 4%;
  }
  .object-2 {
    width: 20px;
    height: 20px;
    border: 3px solid red;
    border-radius: 50%;
    top: 46%;
    left: 34%;
  }

  .object-3 {
    width: 44px;
    height: 44px;
    border: 3px solid red;
    border-radius: 50%;
    top: 86%;
    left: 0%;
  }
  .object-4 {
    width: 36px;
    height: 36px;
    border: 3px solid red;
    border-radius: 50%;
    top: 76%;
    left: 87%;
  }
  .object-5 {
    width: 40px;
    height: 40px;
    border: 3px solid red;
    border-radius: 50%;
    top: 20%;
    left: 66%;
  }
}

@media (max-width: 700px) {
  .image {
    width: 250px;
    height: 250px;
  }
}

@media (max-height: 700px) {
  .image {
    width: 230px;
    height: 230px;
  }

  .object-3 {
    width: 44px;
    height: 44px;
    border: 3px solid red;
    border-radius: 50%;
    top: 83%;
    left: 0%;
  }
}
